import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-46be9af9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_3 = { for: "name" }
const _hoisted_4 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = { for: "surname" }
const _hoisted_7 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_8 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_9 = { for: "email" }
const _hoisted_10 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_11 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_12 = { for: "specialists" }
const _hoisted_13 = { class: "dropdown-item" }
const _hoisted_14 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_15 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_16 = { for: "language" }
const _hoisted_17 = {
  key: 0,
  class: "p-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showDialog) = $event)),
    header: _ctx.modify ? _ctx.$t('patient.edit') : _ctx.$t('patient.new'),
    modal: true,
    class: "p-fluid"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('common.cancel'),
        class: "p-button p-component p-button-secondary p-button-medium",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.save'),
        class: "p-button p-component p-button-info p-button-medium",
        loading: _ctx.submitting,
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('messages.newPatientModalText')), 1),
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('person.name')) + "*", 1),
            _createVNode(_component_InputText, {
              id: "name",
              modelValue: _ctx.patientFormDto.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.patientFormDto.name) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedPatientForm.name.valid })
            }, null, 8, ["modelValue", "class"]),
            (_ctx.showValidation && !_ctx.validatedPatientForm.name.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t(`${_ctx.validatedPatientForm.name.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('person.surname')) + "*", 1),
            _createVNode(_component_InputText, {
              id: "surname",
              modelValue: _ctx.patientFormDto.surname,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.patientFormDto.surname) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedPatientForm.surname.valid })
            }, null, 8, ["modelValue", "class"]),
            (_ctx.showValidation && !_ctx.validatedPatientForm.surname.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t(`${_ctx.validatedPatientForm.surname.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('person.email')) + "*", 1),
            _createVNode(_component_InputText, {
              id: "email",
              modelValue: _ctx.patientFormDto.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.patientFormDto.email) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              class: _normalizeClass({
              'p-disabled': _ctx.modify,
              'p-invalid': _ctx.showValidation && !_ctx.validatedPatientForm.email.valid,
            })
            }, null, 8, ["modelValue", "class"]),
            (_ctx.showValidation && !_ctx.validatedPatientForm.email.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t(`${_ctx.validatedPatientForm.email.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('patient.specialists-short')) + "*", 1),
            _createVNode(_component_MultiSelect, {
              id: "specialists",
              modelValue: _ctx.ownerSpecialistIds,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ownerSpecialistIds) = $event)),
              options: _ctx.specialistOptions,
              optionValue: "professionalId",
              placeholder: _ctx.$t('medicalTest.select-specialist'),
              class: _normalizeClass(["p-column-filter multiselect-custom", { 'p-invalid': _ctx.showValidation && !_ctx.validatedPatientForm.specialistIds.valid, 'p-disabled': _ctx.modify }])
            }, {
              value: _withCtx((slotProps) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.value, (option) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: option.code,
                    class: "specialist-item-value"
                  }, _toDisplayString(_ctx.specialistOptions?.find((specialist) => specialist.professionalId == option)?.name) + " " + _toDisplayString(_ctx.specialistOptions?.find((specialist) => specialist.professionalId == option)?.surname), 1))
                }), 128)),
                (!slotProps.value || slotProps.value.length === 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(slotProps.placeholder), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              option: _withCtx((slotProps) => [
                _createVNode(_component_Avatar, {
                  image: slotProps.option.picture,
                  class: "p-mr-2",
                  size: "small",
                  shape: "circle"
                }, null, 8, ["image"]),
                _createElementVNode("span", _hoisted_13, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.surname), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "placeholder", "class"]),
            (_ctx.showValidation && !_ctx.validatedPatientForm.specialistIds.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_14, _toDisplayString(_ctx.$t(`${_ctx.validatedPatientForm.specialistIds.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('common.language')) + "*", 1),
            _createVNode(_component_Dropdown, {
              id: "language",
              modelValue: _ctx.patientFormDto.language,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.patientFormDto.language) = $event)),
              options: _ctx.languageOptions,
              "option-label": "label",
              "option-value": "value",
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedPatientForm.language.valid })
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.showValidation && !_ctx.validatedPatientForm.language.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.$t(`${_ctx.validatedPatientForm.language.message}`)), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}